import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: 'GTCHAIRHOME' }
    },
    {
        path: '/production/:id',
        name: 'production',
        component: () => import('../views/Production.vue'),
        meta: { title: 'Products' }
    },
    {
        path: '/walkgt',
        name: 'walkgt',
        component: () => import('../views/Walkgt.vue'),
        meta: { title: 'About' }
    },
    {
        path: '/custom-case',
        name: 'customCase',
        component: () => import('../views/CustomCase.vue'),
        meta: { title: 'Projects' }
    },
    {
        path: '/news-list',
        name: 'newlist',
        component: () => import('../views/NewList.vue'),
        meta: { title: 'News' }
    },
    {
        path: '/news-desc/:id',
        name: 'newdesc',
        component: () => import('../views/NewDesc.vue'),
        meta: { title: 'News' }
    },
    {
        path: '/production-list',
        name: 'productionList',
        component: () => import('../views/ProductionList.vue'),
        meta: { title: 'Products' }
    },

    {
        path: '/gt-test',
        name: 'gt-test',
        component: () => import('../views/GtTest.vue'),
        meta: { title: 'Quality' }
    },
    {
        path: '/pp-activity/:id',
        name: 'pp-activity',
        component: () => import('../views/PpActivity.vue'),
        meta: { title: 'Events' }
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/Contact.vue'),
        meta: { title: 'Contact' }
    },
    {
        path: '/video-list',
        name: 'video-list',
        component: () => import('../views/Video.vue'),
        meta: { title: 'Video' }
    },
    {
        path: '/video-desc',
        name: 'video-desc',
        component: () => import('../views/VideoDesc.vue'),
        meta: { title: 'Video' }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title as string
    }
    next()
})

export default router
