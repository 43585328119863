
import { defineComponent, reactive, toRefs } from 'vue'
import { wuRequest } from '@/service'
import { useRouter } from 'vue-router'
export default defineComponent({
    components: {},
    setup() {
        let router = useRouter()
        let footMenu = reactive({
            // 需要显示的菜单
            menu: [
                { name: '办公椅系列', en_name: 'Office Chair Series', id: 20, url: '/production-list?type=办公椅' },
                { name: '电竞椅系列', en_name: 'Gaming Chair Series', id: 21, url: '/production-list?type=电竞椅' },
                { name: '走进高田', en_name: 'About', id: 39, url: '/walkgt?id=40' },
                { name: '检测中心', en_name: 'Quality', id: 44, url: '/gt-test' },
                { name: '新闻中心', en_name: 'News', id: 44, url: '/news-list' },
                {
                    name: '联系我们',
                    en_name: 'Contact',
                    isShow: false,
                    id: 46,
                    url: '/contact?id=46'
                }
            ],
            menuList: []
        })
        const clickMenuList = (id: number, index: number) => {
            wuRequest.get({ url: 'category/' + id }).then((data) => {
                let res = data.data.data
                res.shift()
                footMenu.menuList = res
            })
            //点击前先把所有的菜单列表隐藏起来，然后在指定需要显示的菜单列表
            footMenu.menu.map((item, i) => {
                if (i !== index) {
                    item.isShow = false
                }
            })
            footMenu.menu[index].isShow = !footMenu.menu[index].isShow
        }
        const linkTo = (path: string) => {
            router.push(path)
        }
        return { ...toRefs(footMenu), clickMenuList, linkTo }
    }
})
