import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { ElLoading, ILoadingInstance } from 'element-plus'
import { BASE_URL, TIME_OUT } from './config'
import { WURequestInterceptors } from './types'

class WuRequest {
    instance: AxiosInstance
    loading?: ILoadingInstance
    //在构造中主要实现统一的请求拦载和响应拦载
    constructor(is_show = true) {
        // 创建axios实例 这个实例就相当于直接使用axios一样的效果
        this.instance = axios.create()
        // 2.添加所有的实例都有的拦截器
        this.instance.interceptors.request.use(
            (config) => {
                // console.log('所有的实例都有的拦截器: 请求成功拦截')
                //请求前打开loading
                if (is_show) {
                    this.loading = ElLoading.service({
                        text: 'loadding',
                        lock: true
                    })
                }

                return config
            },
            (err) => {
                // console.log('所有的实例都有的拦截器: 请求失败拦截')
                this.loading?.close()
                return err
            }
        )
        //添加所有实例的响应拦载器
        this.instance.interceptors.response.use(
            (res) => {
                // console.log('所有的实例都有的拦截器: 响应成功拦截')
                this.loading?.close()
                return res
            },
            (err) => {
                // console.log('所有的实例都有的拦截器: 响应失败拦截')
                // 将loading移除
                this.loading?.close()

                // 例子: 判断不同的HttpErrorCode显示不同的错误信息
                if (err.response.status === 404) {
                    // console.log('404的错误~')
                }
                return err
            }
        )
    }
    //实例单独拦载器
    interceptor(interceptors: WURequestInterceptors) {
        this.instance.interceptors.request.use(interceptors?.requestInterceptor, interceptors?.requestInterceptorCatch)
        this.instance.interceptors.response.use(interceptors?.responseInterceptor, interceptors?.responseInterceptorCatch)
    }
    //封装的请求
    request(config: AxiosRequestConfig) {
        return new Promise<AxiosResponse>((resolve, reject) => {
            this.instance
                .request({ ...config })
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    get(config: AxiosRequestConfig, interceptors?: WURequestInterceptors) {
        if (interceptors) {
            this.interceptor(interceptors)
        }
        return this.request({
            ...config,
            method: 'get',
            baseURL: BASE_URL,
            timeout: TIME_OUT
        })
    }
    put(config: AxiosRequestConfig, interceptors?: WURequestInterceptors) {
        if (interceptors) {
            this.interceptor(interceptors)
        }
        return this.request({
            ...config,
            method: 'put',
            baseURL: BASE_URL,
            timeout: TIME_OUT
        })
    }
    post(config: AxiosRequestConfig, interceptors?: WURequestInterceptors) {
        if (interceptors) {
            this.interceptor(interceptors)
        }
        return this.request({
            ...config,
            method: 'post',
            baseURL: BASE_URL,
            timeout: TIME_OUT
        })
    }
    delete(config: AxiosRequestConfig, interceptors?: WURequestInterceptors) {
        if (interceptors) {
            this.interceptor(interceptors)
        }
        return this.request({
            ...config,
            method: 'delete',
            baseURL: BASE_URL,
            timeout: TIME_OUT
        })
    }
}
// 显示loading
const wuRequest = new WuRequest()
//不显示loadin
const wuRequestNotLoading = new WuRequest(false)
export { wuRequestNotLoading, wuRequest }
