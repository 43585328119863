
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue'
import { ElMessageBox } from 'element-plus'
import { wuRequest } from '@/service'
import Lb from '@/components/Lb.vue'
import { url } from '@/service/config'
import $ from 'jquery'
import router from '@/router'
import { RouteLocationRaw } from 'vue-router'

//import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
    components: { Lb },
    setup() {
        let bigVideo = ref<any>(null)
        let t = reactive({
            data: {},
            news: [],
            url,
            bigImg: true
        })

        onMounted(async () => {
            // 保持菜单更新一直在顶部
            document.body.scrollTop = document.documentElement.scrollTop = 0
            let res = await wuRequest.get({
                url: '/'
            })
            if (res.data.code === 10001) {
                t.data = res.data.data
                t.news = res.data.data.news.slice(0, 3)
            } else {
                ElMessageBox.alert('数据请求错误，请稍后重试', '数据异常', {
                    confirmButtonText: 'OK'
                })
                return false
            }
        })
        const playStart = () => {
            bigVideo.value?.play()
            t.bigImg = false
        }
        const linkTo = (url: RouteLocationRaw) => {
            router.push(url)
        }

        return { ...toRefs(t), linkTo, bigVideo, playStart }
    }
})

window.onload = function () {
    $('.videoList').each(function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var local1 = this as any
        local1.autoplay = true // 自动播放
        local1.loop = true // 循环播放
        local1.muted = true // 关闭声音，如果为false,视频无法自动播放
        if (local1.paused) {
            //判断是否处于暂停状态
            local1.play() //开启播放
        } else {
            local1.pause() //停止播放
        }
    })
}
