let BASE_URL = ''
const TIME_OUT = 10000
if (process.env.NODE_ENV === 'development') {
    BASE_URL = 'https://gtchair.com/api'
} else if (process.env.NODE_ENV === 'production') {
    BASE_URL = 'https://gtchair.com/api'
} else {
    BASE_URL = 'https://gtchair.com/api'
}
const url = 'https://gtchair.com'
export { BASE_URL, TIME_OUT, url }
