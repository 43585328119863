
import { defineComponent, ref, reactive, toRefs } from 'vue'
import Nav from '@/components/Nav.vue'
import Foot from '@/components/Foot.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import DownMenu from '@/components/DownMenu.vue'
import { wuRequest } from './service'

export default defineComponent({
    name: ' App',
    components: {
        Nav,
        Foot,
        DownMenu
    },
    setup() {
        let isShowMenu = ref(false)
        let isShwoLanguage = ref(false)
        // isMenu 表示是否有下级菜单，如果为否就是一个简单的连接
        let status = reactive<any>({
            currentData: []
        })

        let id = ref(0)
        // 是否显示菜单
        const changMenu = () => {
            isShwoLanguage.value = false
            id.value = 0
            wuRequest
                .get({
                    url: 'category/' + id.value
                })
                .then((data) => {
                    status.currentData = data.data.data //data.data.data
                })
            isShowMenu.value = !isShowMenu.value
        }
        const changPath = (changId: number) => {
            id.value = changId

            wuRequest
                .get({
                    url: 'category/' + id.value
                })
                .then((data) => {
                    status.currentData = data.data.data //data.data.data
                })
        }
        const listLink = (changId: number) => {
            id.value = changId
        }
        const changMenuLanguage = () => {
            isShowMenu.value = false
            isShwoLanguage.value = !isShwoLanguage.value
        }

        const colseMenu = () => {
            isShowMenu.value = false
            isShwoLanguage.value = false
        }
        return { isShowMenu, isShwoLanguage, changMenu, changMenuLanguage, colseMenu, changPath, listLink, ...toRefs(status) }
    }
})
