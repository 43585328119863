import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import store from './store'
import axios from 'axios'
axios.defaults.withCredentials = true
//import '@/views/axios_demo'
const app = createApp(App)
app.use(store).use(router).use(ElementPlus).mount('#app')
