
import { defineComponent, ref } from 'vue'
import { url } from '@/service/config'
export default defineComponent({
    name: 'Lb',
    props: ['lb'],
    setup() {
        const base_url = ref(url)
        return { base_url }
    }
})
