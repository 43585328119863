
import { CaretBottom } from '@element-plus/icons'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
    props: ['isShwoLanguage'],
    emits: ['changMenu', 'changMenuLanguage', 'colseMenu'],
    components: {
        CaretBottom
    },
    setup(pops, ctx) {
        const route = useRouter()
        const changMenu = () => {
            ctx.emit('changMenu', '0')
        }
        const linkTo = (url: string) => {
            ctx.emit('colseMenu')
            route.push(url)
        }
        const linkHome = () => {
            ctx.emit('colseMenu')
            route.push('/')
        }
        const changMenuLanguage = () => {
            ctx.emit('changMenuLanguage')
        }
        const colseMenu = () => {
            ctx.emit('colseMenu')
        }

        return { changMenu, linkHome, changMenuLanguage, colseMenu, linkTo }
    }
})
