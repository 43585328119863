const appModule = {
    namespaced: true,
    state() {
        return {
            menuData: [
                {
                    menuName: '产品中心',
                    isMenu: true,
                    url: '',
                    child: [
                        {
                            menuName: '办公椅系列',
                            isMenu: true,
                            url: '',
                            child: [
                                {
                                    menuName: '办公椅系列',
                                    isMenu: true,
                                    url: ''
                                },
                                {
                                    menuName: '电竞椅系列',
                                    isMenu: true,
                                    url: ''
                                }
                            ]
                        },
                        { menuName: '电竞椅系列', isMenu: true, url: '' }
                    ]
                },
                {
                    menuName: '走进高田',
                    isMenu: true,
                    url: '',
                    child: [
                        { menuName: '高田简介', isMenu: false, url: '' },
                        { menuName: '品牌活动', isMenu: false, url: '' },
                        { menuName: '权威认证', isMenu: false, url: '' }
                    ]
                },
                {
                    menuName: '检测中心',
                    isMenu: true,
                    url: '',
                    child: [
                        { menuName: '专业设备', isMenu: false, url: '' },
                        { menuName: '产品证书', isMenu: false, url: '' }
                    ]
                },
                { menuName: '客户案例', isMenu: false, url: '' },
                { menuName: '新闻中心', isMenu: false, url: '' },
                { menuName: '联系我们', isMenu: false, url: '' }
            ]
        }
    },
    getters: {},
    mutations: {
        increment(state: any) {
            state.homeCounter++
        }
    }
}

export default appModule
