
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    emits: ['changMenu', 'changPath', 'listLink'],
    name: 'DownMenu',
    props: ['menuData'],
    setup(props, ctx) {
        const router = useRouter()
        const colse = () => {
            ctx.emit('changMenu')
        }
        const currentPath = (id: number) => {
            ctx.emit('changPath', id)
        }
        const linkDesc = (id: number, index: number) => {
            //跳转前先关闭导航菜单
            colse()
            if (props.menuData[index].name === '办公椅' || props.menuData[index].name === '电竞椅') {
                router.push({
                    path: '/production-list',
                    query: {
                        type: props.menuData[index].name === '办公椅' ? '办公椅' : '电竞椅'
                    }
                })
            } else if (props.menuData[0].name === '走进高田') {
                router.push('/walkgt?id=' + id)
            } else if (props.menuData[index].name === '客户案例') {
                router.push('/custom-case')
            } else if (props.menuData[index].name === '新闻中心') {
                router.push('/news-list')
            } else if (
                props.menuData[index].name === '专业设备' ||
                props.menuData[index].name === 'Professional Equipment' ||
                props.menuData[index].name === '产品证书' ||
                props.menuData[index].name === 'Products Certificates'
            ) {
                let id = props.menuData[index].name === '专业设备' || props.menuData[index].name === 'Professional Equipment' ? 49 : 50
                router.push({ path: '/gt-test', query: { id } })
            } else if (props.menuData[index].name === '联系我们') {
                router.push({ path: '/contact', query: { id } })
            } else if (props.menuData[index].name === '视频中心') {
                router.push({ path: '/video-list' })
            }
            //router.push('/production/' + id)
        }
        return { colse, currentPath, linkDesc }
    }
})
